import image1 from '../assets/images/ns_tunnel.png';
const siteOptionData = [
    {
      id: 1,
      siteName: "NS TUNNEL BRAND",
      image: image1,
      description: "খুব সহজে ডাওনলোড করতে পারবেন. নিচে দিয়া লিংকে ক্লিক করুন",
      siteLink: "https://nstunnelvpn.xyz/login"
    }
  ];
  export default siteOptionData;
